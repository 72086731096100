export default {
  namespaced: true,
  state: {
    isAuthtenticated: false,
    authToken: '',
    user: '',
  },
  mutations: {
  },
  actions: {},
  getters: {
  },
};
