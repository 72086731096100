import { render, staticRenderFns } from "./AboutPageDataSource.vue?vue&type=template&id=3164e955&scoped=true&"
import script from "./AboutPageDataSource.vue?vue&type=script&lang=js&"
export * from "./AboutPageDataSource.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3164e955",
  null
  
)

export default component.exports